.ant-select-multiple .ant-select-selection-item {
	background: #4da1ff;
	border-color: #4da1ff;
	color: #fff;
	border-radius: 25;
	padding: 0 15px;
}

.ant-select-multiple .ant-select-selection-item-remove svg {
	color: #fff;
}

.ant-form-item-label label {
	font-size: 12px;
	color: #6a6a6a;
	font-family: 'Source Sans Pro';
	&:after {
		content: '';
	}
}
